<template>
    <div id="app">
        <div class="back">
            <video
                v-if="!mobile"
                src="/static/back.mp4"
                playsinline=""
                loop=""
                autoplay="autoplay"
                muted=""
                preload="auto"
                id="video-bg"
            >
                <source src="/static/back.mp4" type="video/mp4" />
            </video>

            <video
                v-else
                src="/static/mobile_back.mp4"
                playsinline=""
                loop=""
                autoplay="autoplay"
                muted=""
                preload="auto"
                id="video-bg"
            >
                <source src="/static/mobile_back.mp4" type="video/mp4" />
            </video>

            <div class="title-container">
                <span class="title">The world is unstable.</span>
                <span class="description">it's time to</span>
            </div>

            <img src="@/assets/alla.svg" alt="logo" class="logo" />

            <!-- <div class="input-container">
                <input
                    type="text"
                    class="email-input"
                    placeholder="Your Email"
                />
                <div class="bth"><span>GO</span></div>
            </div> -->

            <div class="input-container">
                <input
                    type="text"
                    class="email-input"
                    placeholder="Your Email"
                />
                <a href="" class="bth">GO</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            width: 0,
            height: 0,
            mobile: false,
        };
    },
    methods: {
        checkScreen() {
            if (window.innerWidth < 1024) {
                this.mobile = true;
            }
        },
    },
    created() {
        this.checkScreen();
    },
};
</script>

<style lang="scss">
@import "@/styles/config";

* {
    font-family: $main-font;
    transition: 0.55s opacity, 0.55s visibility;
}

@keyframes ani {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

body {
    margin: 0;
}

.back {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;
}

video {
    position: absolute;
    width: 100vw;
    z-index: -1;
    top: ptr(-100px);
}

.title-container {
    margin: auto;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: ptr(110px);
    opacity: 0;
    animation: ani 1.5s forwards;

    .title {
        font-size: ptr(36px);
    }

    .description {
        padding-top: ptr(5px);
        font-size: ptr(22px);
        color: #d3d3d3;
    }
}

.logo {
    display: block;
    margin: auto;
    margin-top: ptr(220px);
    width: ptr(155px);
    height: ptr(58px);
    opacity: 0;
    animation: ani 2s forwards;
    animation-delay: 1s;
}

.input-container {
    width: ptr(294px);
    height: ptr(63px);
    opacity: 0;
    animation: ani 1.5s forwards;
    animation-delay: 2.5s;
    margin: auto;
    margin-top: ptr(280px);
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: ptr(32px);

    input {
        outline: none;
        border: none;
        background: transparent;
        color: white;
    }

    a {
        text-decoration: none;
        color: white;
    }
}

@media (max-width: 1024px) {
    video {
        width: 130%;
        top: ptr(-70px);
        right: ptr(-50px);
    }

    .title-container {
        margin-top: ptr(79px);

        .title {
            font-size: ptr(22px);
        }

        .description {
            font-size: ptr(15px);
        }
    }

    .logo {
        width: ptr(75px);
        height: ptr(28px);
        margin-top: ptr(110px);
    }

    .input-container {
        width: ptr(247px);
        height: ptr(50px);
        margin-top: ptr(140px);

        input {
            font-size: ptr(11px);
        }
    }
}
</style>
